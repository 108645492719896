:root {
    --color-primary: #173749;
    --color-white: #ffffff;
    --color-selected: #173749;
    --color-hover: #f5f5f5;
    --height: 65px;
    --toggler: 19px;
}

.theme {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.expanded .theme {

}

.theme.default .toggler {
    fill: var(--color-primary);
}

.theme .toggler {
    stroke: var(--color-primary);
    cursor: pointer;
    display: block;
    margin: calc((var(--height) - var(--toggler)) / 2);
    width: var(--toggler);
    transition-duration: 0.3s;
    transition-property: transform;
}
.theme.active .toggler {
    stroke: var(--color-white);
}
.theme.default.active .toggler {
    fill: var(--color-white);
}
.theme.active:hover .toggler {
    stroke: var(--color-primary);
}
.theme.default.active:hover .toggler {
    fill: var(--color-primary);
}

.theme .label {
    cursor: pointer;
    flex-grow: 1;
}

.expanded .theme:not(.default) .toggler {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

ul.pillars {
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.expanded ul.pillars {
    max-height: 500px;
    transition: max-height 0.3s ease-in;
}

li.pillar {
    list-style: none;
    padding: 0 0 0 var(--height);
    margin: 0;
    height: var(--height);
    align-items: center;
    display: flex;
    cursor: pointer;
}

.theme:hover,
li.pillar:hover {
    background-color: var(--color-hover);
    color: var(--color-primary);
}

.active {
    background-color: var(--color-selected);
    color: var(--color-white);
}

.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: calc((var(--height) - var(--toggler)) / 2);
    padding-bottom: 16px;
}

.searchInput {
    background: #eeeeee;
}

.searchIcon {
    margin: 0 calc((var(--height) - var(--toggler)) / 2);
    width: var(--toggler);
}
