html,
body {
    background-color: #ffffff;
    height: 100%
}
#root, .App{
    height:100%;
}
.container {
    width: 100vw;
    height: 100vh;
    padding: 40px;

    .flex {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card {
        width: 480px;
        padding: 24px 40px;

        form {
            margin-top: 40px;

            .field {
                margin-bottom: 1.5rem;
                text-align: left;
            }
        }
    }
}

.p-dialog {
    code {
        background-color: var(--bluegray-50);
        padding: 16px 12px;
    }
}

.col,
.col-2 {
    display: flex;
    align-items: center;
}
.col {
    justify-content: flex-end;
}

.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}
.swarm{
    height: 100%;
}
.swarm iframe {
    border:none;
}
.clear{
    clear:both;
}
.navbar-brand img {
    display: block!important;
    margin: 0 auto;
    height: 48px;
}
.width-33 {
    width: calc(100% / 3);
}
.header {
    width: 100%;
    height: 80px;
    background: #173749;
    display: flex;
    align-items: center;
}
.body-class {
    width: 100%;
    height: calc(100% - 80px);
}
.main-menu-image{
    width: 32px;
    height: 32px;
    margin: 5px 30px 5px 10px;
}
.shadow.offcanvas-header{
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.15) !important;
    padding-left: 0;
}
.navbar-collapse{
    background: #ffffff;
}
.navbar{
    width: 100%;
    padding:0;
}
.navbar a {
    color: #173749;
    font-size: 1.4em;
}
.navbar .container-fluid{
    padding: 0;
}
.menu-form{
    padding: 0 10px 10px;
    width: 100%;
}
.container.log-in{
    max-width: 100%;
}
.offcanvas-body{
    padding-left: 0;
    padding-right:0;
}
.offcanvas-title {
    margin-left: 65px;
    color:#173749;
    font-size: 24px;
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: 0;
    --bs-offcanvas-border-color: none;
    --bs-offcanvas-box-shadow: none;
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5;
}
.sign-out{
    display:block;
    float: right;
    width: 40px;
    height: 40px;
    margin-right: 40px;
    border: 0;
    background-image: url('../assets/sign-out.png');
    background-repeat: no-repeat;
    background-size: 40px 40px;
}
.sign-out.btn:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}
.btn-close{
    --bs-btn-close-bg: url('../assets/close-btn.png') !important;
    --bs-btn-close-opacity: 1;
    --bs-btn-close-hover-opacity: 1;
}

.log-in .p-card {
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
}
