.beyond-button {
    background: #173749;
    border-radius: 10px;
    padding: 1rem 1rem;
}
.beyond-button span{
    font-size: 16px;
    font-weight: normal;
    font-family: "Inter", sans-serif;
}
.login-logo{
    display: block;
    margin: 30px auto 60px;
    width: 168px;
    height: 57px;
}
.log-in {
    background: #173749;
}
.forgot-password {
    text-align: center;
}
.login-href {
    color: #212325;
    background: none;
    text-decoration: none;
}
.container .card {
    background: #fff;
    border: none;
    border-radius: 30px;
    width: 480px;
    padding: 60px 50px;
}
#email, .p-password-input {
    background: #d6d6d6;
    color: #858585;
    padding: 1rem 1rem;
    border-radius: 10px;
    border: none;
}

label[for=email], label[for=password] {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: #858585;
}

@media screen and (max-width: 960px) {
    .container .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .container .card {
        width: 100%;
        min-width: 0;
    }
}
.forgot-password a {
    text-decoration: underline;
}
