.menuBtn {
    margin-left: 30px;
    background: none;
    cursor: pointer;
    border: none;
}
.menuBtnIcon {
    height: 50px;
    width: 50px;
    fill: #ffffff;
}
